
























import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'og-card': () => import('@/modules/common/components/posts/og-card.vue'),
  },
})
export default class extends Vue {
  @Prop({
    default: () => {
      return {}
    },
  })
  editorBlock

  @Prop({
    default: () => {
      return {}
    },
  })
  editorHandler
}
